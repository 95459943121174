import { environment } from '../../../environments/environment';

const api = environment.host;

export const API_URLS = {
  token: {
    url: api + '/history/token',
    getRegularToken: (tempToken: string) => `${API_URLS.token.url}/${tempToken}`,
  },
  spinHistory: {
    url: api + '/history/spin-history',
    count: api + '/history/count',
    byId: (id: string) => `${API_URLS.spinHistory.url}/${id}`,
    current: api + '/history/current-session-spins',
    currentCount: api + '/history/current-session-spins-count',
    currentById: (id: number) => api + `/history/current-session-spin/${id}`,
  },
};
