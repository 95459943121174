import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { GAMES_REEL_SYMBOLS } from '../../../shared/constants/reel-symbols/games-reels';
import { GAMES_WIN_LINES } from '../../../shared/constants/winning-lines/games-win-lines';
import { Subscription } from 'rxjs';
import { SpinDetailsService } from './spin-details.service';
import { SpinDetailModel } from '../../../shared/api/models/spin-detail.model';
import { AuthService } from '../../../shared/api/services/auth.service';
import { HISTORY_TAB_CONFIG } from '../../../shared/configs/history-tab.config';

@Component({
  selector: 'app-spin-details',
  templateUrl: './spin-details.component.html',
  styleUrls: ['./spin-details.component.scss'],
})
export class SpinDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() spinId: any;
  @Input() showTab: string;
  @Input() sessionId: number;
  private subscription: Subscription = new Subscription();
  private reelsMap = GAMES_REEL_SYMBOLS;
  private winLinesMap = GAMES_WIN_LINES;
  public reelsImagesByGameId: { [key: string]: string };
  public winLinesImagesByGameId: { [key: string]: string };
  public gameIdInSession = 1;
  public spinDetailData: SpinDetailModel;
  public reelsArr: string[] = [];
  public winLinesArr;
  private regularToken: { [key: string]: string } = { token: null };
  public wildExpandedImage = 'b_expanded';

  constructor(
    private sessionDetailsService: SpinDetailsService,
    private authService: AuthService
  ) {}

  public ngOnInit(): void {}

  public ngOnChanges(changes: SimpleChanges): void {
    this.checkDisplayFlag();
  }

  private checkDisplayFlag(): void {
    if (this.showTab === HISTORY_TAB_CONFIG.CURRENT) {
      this.setSpinCurrentSessionDetail();
    } else {
      this.setRegularToken();
    }
  }

  private setSpinCurrentSessionDetail(): void {
    this.addSubscription(
      this.sessionDetailsService
        .getSpinCurrentSessionDetail(this.spinId, { sessionId: this.sessionId })
        .subscribe((res: SpinDetailModel) => {
          this.setSpinDate(res);
        })
    );
  }

  private setSpinHistoryDetail(): void {
    this.addSubscription(
      this.sessionDetailsService
        .getSpinHistoryDetail(this.spinId, { ...this.regularToken })
        .subscribe((res: SpinDetailModel) => {
          this.setSpinDate(res);
        })
    );
  }

  private setSpinDate(res: SpinDetailModel): void {
    this.spinDetailData = res;
    this.reelsImagesByGameId = this.reelsMap[this.spinDetailData.gameSkinId];
    this.winLinesImagesByGameId =
      this.winLinesMap[this.spinDetailData.gameSkinId];
    this.reelsArr = this.spinDetailData.reels;
    this.winLinesArr = this.spinDetailData.win.ways;
  }

  private setRegularToken(): void {
    this.addSubscription(
      this.authService.tokenInfo$.subscribe((res: string) => {
        if (res) {
          this.regularToken.token = res;
          this.setSpinHistoryDetail();
        }
      })
    );
  }

  public checkWild(col): boolean {
    const checkMathModel = this.spinDetailData.originalGameId === 1;
    const checkWin = this.spinDetailData.win.ways.length !== 0;
    const checkWildReel = col.find((reel) => reel === 'b');
    return checkMathModel && checkWin && checkWildReel;
  }

  private addSubscription(subscription: Subscription): void {
    this.subscription.add(subscription);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
