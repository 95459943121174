export const EXTERNAL_GAME_REELS: { [key: string]: string } = {
    a: '/assets/reel-symbols/external-game/no-photo.jpg',
    b: '/assets/reel-symbols/external-game/no-photo.jpg',
    b_expanded: '/assets/reel-symbols/external-game/no-photo.jpg',
    c: '/assets/reel-symbols/external-game/no-photo.jpg',
    d: '/assets/reel-symbols/external-game/no-photo.jpg',
    e: '/assets/reel-symbols/external-game/no-photo.jpg',
    f: '/assets/reel-symbols/external-game/no-photo.jpg',
    g: '/assets/reel-symbols/external-game/no-photo.jpg',
    h: '/assets/reel-symbols/external-game/no-photo.jpg',
    i: '/assets/reel-symbols/external-game/no-photo.jpg',
    j: '/assets/reel-symbols/external-game/no-photo.jpg',
    k: '/assets/reel-symbols/external-game/no-photo.jpg',
    l: '/assets/reel-symbols/external-game/no-photo.jpg',
    m: '/assets/reel-symbols/external-game/no-photo.jpg',
    A: '/assets/reel-symbols/external-game/no-photo.jpg',
    G: '/assets/reel-symbols/external-game/no-photo.jpg',
    K: '/assets/reel-symbols/external-game/no-photo.jpg',
    Q: '/assets/reel-symbols/external-game/no-photo.jpg',
  };
  