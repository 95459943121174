<div class="container-fluid">
  <hr class="mt-2 mb-1">
  <div class="wrapper"
       infiniteScroll
       [infiniteScrollDistance]="1"
       [infiniteScrollThrottle]="2"
       (scrolled)="onScroll()"
       [scrollWindow]="false"
       [infiniteScrollDisabled]="infiniteScrollStop"
  >
    <div class="table-wrapper">
      <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef>{{'BET_ID' | translate}}#</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="game-name">{{element.gameName}}</span>
            <span *ngIf="element.feature === 'Free spins'" class="feature-color">
              {{'FREE_SPINS' | translate}}
            </span>
            <span *ngIf="element.feature === 'Bonus'" class="feature-color">
              {{'BONUS' | translate}}
            </span>
            <span>{{element.id}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="datetime">
          <mat-header-cell *matHeaderCellDef>{{'DATE_TIME' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element" class="text-left column-date">
            <span>{{element.dateTime | date:'dd.MM.yyyy' : '+0000'}}</span>
            <span>{{element.dateTime | date:'hh:mm:ss aa' : '+0000'}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="bet">
          <mat-header-cell *matHeaderCellDef>{{'BET' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.bet | currency}}
            <span class="iso-code">{{element.currency}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="wonAmount">
          <mat-header-cell *matHeaderCellDef>{{'AMOUNT_WIN' | translate}}</mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            [class.win-color]="element.wonAmount !== 0"
          >
            {{element.wonAmount | currency}}
            <span class="iso-code">{{element.currency}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="newBalance">
          <mat-header-cell *matHeaderCellDef>{{'BALANCE' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.newBalance | currency}}
            <span class="iso-code">{{element.currency}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="isNftWin">
          <mat-header-cell *matHeaderCellDef>{{'IS_NFT_WIN' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span [ngClass]="element.isNftWin ? 'nft-win-text' : ''">
              {{element.isNftWin ? 'Yes' : 'No'}}
            </span>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row
          *matRowDef="let row
          columns: displayedColumns;
          let i = index"
          [ngClass]="chosenRow === i ? 'active' : 'regular'"
          (click)="getSpinId(row.id, i)"
        ></mat-row>
      </mat-table>
    </div>
    <div *ngIf="dataSource?.length === 0" class="text-center mt-3">
      <h3>{{'NO_DATA' | translate}}</h3>
    </div>
  </div>
</div>
<div *ngIf="dataSource === null">
  <app-loading-spinner></app-loading-spinner>
</div>

