import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GameHistoryComponent } from './game-history.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ReactiveFormsModule } from '@angular/forms';
import { ApiGameHistoryService } from '../../shared/api/services/api-game-history.service';
import { GameHistoryService } from './game-history.service';
import { HttpClientModule } from '@angular/common/http';
import { HistoryTodayModule } from './history-today/history-today.module';
import { SpinDetailsModule } from './spin-details/spin-details.module';
import { HistoryCurrentModule } from './history-current/history-current.module';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '../../shared/components/components.module';


@NgModule({
  declarations: [
    GameHistoryComponent,
  ],
  imports: [
    CommonModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    HttpClientModule,
    HistoryTodayModule,
    HistoryCurrentModule,
    SpinDetailsModule,
    TranslateModule,
    ComponentsModule,
  ],
  providers: [
    ApiGameHistoryService,
    GameHistoryService,
  ],
  exports: [
    GameHistoryComponent,
  ]
})
export class GameHistoryModule { }
