export const VIKINGS_HERO_REELS: { [key: string]: string } = {
  a: '/assets/reel-symbols/vikings-hero/Scatter.png',
  b: '/assets/reel-symbols/vikings-hero/Wild.png',
  c: '/assets/reel-symbols/vikings-hero/h1.png',
  d: '/assets/reel-symbols/vikings-hero/h2.png',
  e: '/assets/reel-symbols/vikings-hero/h3.png',
  f: '/assets/reel-symbols/vikings-hero/h4.png',
  g: '/assets/reel-symbols/vikings-hero/h5.png',
  h: '/assets/reel-symbols/vikings-hero/A.png',
  i: '/assets/reel-symbols/vikings-hero/K.png',
  j: '/assets/reel-symbols/vikings-hero/Q.png',
  k: '/assets/reel-symbols/vikings-hero/J.png',
  l: '/assets/reel-symbols/vikings-hero/10.png',
  m: '/assets/reel-symbols/vikings-hero/9.png',
};
