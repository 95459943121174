import { ISpinData } from '../interfaces/spin-data.interface';

export class SpinDataModel {
  readonly bet: number;
  readonly dateTime: string;
  readonly id: string;
  readonly newBalance: number;
  readonly wonAmount: number;
  readonly currency?: string;
  readonly betType?: string;
  readonly brandName?: string;
  readonly feature?: string;
  readonly gameName?: string;
  readonly operatorName?: string;
  readonly outcome?: string;
  readonly playerId?: number;
  readonly roundId?: number;
  readonly isNftWin?: boolean;


  constructor(input: ISpinData) {
    Object.assign(this, {...input});
    this.dateTime = input.datetime;
  }
}
