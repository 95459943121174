import { ISpinDetail } from '../interfaces/spin-detail.interface';
import { WinModel } from './win.model';
import { IWin } from '../interfaces/win.interface';

export class SpinDetailModel {
  readonly gameSkinId: number;
  readonly originalGameId: number;
  readonly gameId: number;
  readonly reels: string[];
  readonly totalReward: number;
  readonly win: IWin;
  readonly freeSpinsInfo: number;
  readonly freeRoundInfo: number;
  readonly currency: string;
  readonly nftImage: null | string;
  readonly nftName: null | string;

  constructor(input: ISpinDetail) {
    Object.assign(this, {...input});
    this.win = new WinModel(input.win);
  }
}
