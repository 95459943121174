<div *ngIf="!spinnerContent">
  <div class="container-fluid mt-3">
    <div class="row">
      <div class="col text-center">
        <h2 class="main-title">
          {{'HISTORY_TITLE' | translate}}
        </h2>
      </div>
    </div>
    <form [formGroup]="buttonsGroup" *ngIf="showForPlayers">
      <div class="row">
        <div class="col text-center">
          <mat-button-toggle-group formControlName="buttons" class="btn-group" aria-label="Basic example">
            <mat-button-toggle
              [value]="displayedTabs.CURRENT"
            >
              {{displayedTabs.CURRENT | translate}}
            </mat-button-toggle>
            <mat-button-toggle
              [value]="displayedTabs.HISTORY"
            >
              {{displayedTabs.HISTORY | translate}}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </form>

  </div>
  <app-history-today
    *ngIf="buttonsGroup.value.buttons === displayedTabs.HISTORY"
    (setSpinId)="todaySpinId($event)"
  ></app-history-today>
  <app-history-current
    (setSpinId)="currentSpinId($event)"
    (setSessionId)="currentSessionId($event)"
    *ngIf="buttonsGroup.value.buttons === displayedTabs.CURRENT"
  ></app-history-current>
  <app-spin-details
    [spinId]="spinId"
    [showTab]="buttonsGroup.controls.buttons.value"
    [sessionId]="sessionId"
    *ngIf="openSessionDetails"
  ></app-spin-details>
  <div class="container" *ngIf="!spinId">
    <div class="row mt-5">
      <div class="col text-center">
        <h2>{{'NO_BET' | translate}}</h2>
      </div>
    </div>
  </div>
</div>
<app-content-spinner *ngIf="spinnerContent"></app-content-spinner>
