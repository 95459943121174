import { QUEENSLAND_GHOST_REELS } from './queensland-ghost-reels';
import { PIRATE_O_PAY_REELS } from './pirate-o-pay-reels';
import { PANDA_RICHES_REELS } from './panda-riches-reels';
import { GENIE_GOLD_REELS } from './genie-gold-reels';
import { LAPLAND_GIFTS_REELS } from './lapland-gifts-reels';
import { GODS_OF_RICHES_REELS } from './gods-of-riches-reels';
import { VIKINGS_HERO_REELS } from './vikings-hero-reels';
import { EXTERNAL_GAME_REELS } from './external-game-reels';

export const GAMES_REEL_SYMBOLS: { [key: number]: { [key: string]: string } } = {
  1: QUEENSLAND_GHOST_REELS,
  2: PIRATE_O_PAY_REELS,
  3: GENIE_GOLD_REELS,
  4: LAPLAND_GIFTS_REELS,
  5: PANDA_RICHES_REELS,
  6: GODS_OF_RICHES_REELS,
  7: VIKINGS_HERO_REELS,
  8: EXTERNAL_GAME_REELS,
};
