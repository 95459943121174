export const PIRATE_O_PAY_REELS: { [key: string]: string } = {
  a: '/assets/reel-symbols/pirate-o-pay/scatter.png',
  b: '/assets/reel-symbols/pirate-o-pay/wild.png',
  c: '/assets/reel-symbols/pirate-o-pay/h1.png',
  d: '/assets/reel-symbols/pirate-o-pay/h2.png',
  e: '/assets/reel-symbols/pirate-o-pay/h3.png',
  f: '/assets/reel-symbols/pirate-o-pay/h4.png',
  g: '/assets/reel-symbols/pirate-o-pay/a.png',
  h: '/assets/reel-symbols/pirate-o-pay/k.png',
  i: '/assets/reel-symbols/pirate-o-pay/q.png',
  j: '/assets/reel-symbols/pirate-o-pay/j.png',
  k: '/assets/reel-symbols/pirate-o-pay/10.png',
};
